import { SearchIcon } from "@heroicons/react/solid";
import { Dropdown, WatchItem } from "../components";
import { useSearchParams } from "react-router-dom";
import { useUtilStateContext } from "../context/UtilState";
import { useWatchListContext } from "../context/WatchList";
import { StoreList, StoreListEnglishToChinese } from "../constants";
import { useEffect, useState, useRef, useCallback } from "react";
import { WatchItemInterface } from "../Interfaces";
import { debounce } from "lodash";
import { Switch } from "@headlessui/react";
const saleFilterOptions = {
    all: "全部",
    unsold: "未售出",
    sold: "已售出",
    priceRaise: "上漲",
    priceDrop: "下降"
};
const categoryFilterOptions: { [key: string]: string[] | null } = {
    全部品牌: null,
    勞力士: ["rolex", "勞力士"],
    百達翡麗: ["patek", "philippe", "百達翡麗"],
    愛彼: ["audemars", "piguet", "愛彼", "ap"],
    歐米茄: ["omega", "歐米茄"],
    沛納海: ["panerai", "沛納海"],
    "理查德·米勒": ["richard", "mille", "理查德", "米勒"],
    卡地亞: ["cartier", "卡地亞"],
    香奈兒: ["chanel", "香奈兒"],
    法蘭穆勒: ["franck", "muller", "法蘭穆勒"],
    宇舶錶: ["hublot", "宇舶錶"],
    伯爵: ["piaget", "伯爵"],
    浪琴: ["longines", "浪琴"],
    萬國: ["iwc", "萬國"],
    愛馬仕: ["hermes", "愛馬仕"],
    豪雅: ["tag", "heuer", "豪雅"],
    朗格: ["sohne", "lange", "朗格"],
    柏萊士: ["bell", "ross", "柏萊士"],
    寶珀: ["blancpain", "寶珀"],
    BOMBERG: ["bomberg"],
    寶璣: ["breguet", "寶璣"],
    百年靈: ["breitling", "百年靈"],
    寶格麗: ["bvlgari", "寶格麗"],
    寶齊萊: ["carl", "bucherer", "寶齊萊"],
    蕭邦: ["chopard", "蕭邦"],
    瑞寶: ["chronoswiss", "瑞寶"],
    崑崙: ["corum", "崑崙"],
    卡斯托斯: ["cvstos", "卡斯托斯"],
    康斯登: ["frederique", "constant", "康斯登"],
    芝柏錶: ["girard", "perregaux", "芝柏錶"],
    格拉蘇蒂: ["glashutte", "original", "格拉蘇蒂"],
    格林漢: ["graham", "格林漢"],
    特級精工: ["grand", "seiko", "特級精工"],
    亨利慕時: ["moser", "cie", "亨利慕時"],
    雅克德羅: ["jaquet", "雅克德羅"],
    積家: ["jaeger", "coultre", "積家"],
    捷克豹: ["jacob", "捷克豹"],
    艾美: ["maurice", "lacroix", "艾美"],
    萬寶龍: ["mont", "blanc", "萬寶龍"],
    NOMOS: ["nomos"],
    美度: ["mido", "美度"],
    豪利時: ["oris", "豪利時"],
    雷達: ["rado", "雷達"],
    羅杰杜彼: ["roger", "dubuis", "羅杰杜彼"],
    "羅曼傑‧羅姆": ["RJ", "羅曼傑", "羅姆"],
    帝舵: ["tudor", "帝舵"],
    天梭: ["tissot", "天梭"],
    雅典: ["ulysse", "nardin", "雅典"],
    江詩丹頓: ["vacheron", "constantin", "江詩丹頓"],
    真力時: ["zenith", "真力時"]
};

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}
function Search() {
    // Contexts
    const { allWatchesList, watchList, getImageCache } = useWatchListContext();
    const { selectedSearchStore, setSelectedSearchStore } = useUtilStateContext();
    const [searchParams, setSearchParams] = useSearchParams();

    // Data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [filteredList, setFilteredList] = useState<any[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [egpsFilteredList, setEGPSFilteredList] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentlyLoaded, setCurrentlyLoaded] = useState(48);

    // State
    const [shouldMatchWebsite, setShouldMatchWebsite] = useState(false);
    const allWatchesExceptEgps = allWatchesList.filter((watch: WatchItemInterface) => watch.store !== "EGPS");
    const scrollRef = useRef<HTMLDivElement>(null);
    const delayedFilterList = useCallback(
        debounce(
            (searchQuery, saleFilter, selectedSearchStore, categoryFilter) =>
                filterList(searchQuery, saleFilter, selectedSearchStore, categoryFilter),
            1000
        ),
        [watchList, allWatchesList]
    );
    const [saleFilter, setSaleFilter] = useState("all");
    const [categoryFilter, setCategoryFilter] = useState("全部品牌");
    const [isComparingEGPS, setIsComparingEGSP] = useState(false);

    const loadMore = () => {
        if (currentlyLoaded > filteredList.length) {
            return;
        } else {
            setCurrentlyLoaded(currentlyLoaded + 48);
        }
    };

    const filterList = (
        searchQuery: string,
        saleFilter: string,
        selectedSearchStore: string,
        categoryFilter: string
    ) => {
        const { current } = scrollRef;
        const watchDropdownMap = { All: allWatchesExceptEgps, AllEGPS: allWatchesList, ...watchList };
        let localFilteredList = watchDropdownMap[selectedSearchStore];
        let localEGPSFilteredList = watchDropdownMap.EGPS;
        if (current) {
            current.scrollTo(0, 0);
        }
        setCurrentlyLoaded(48);
        if (!localFilteredList) {
            setFilteredList([]);
            return;
        }

        if (saleFilter === "sold") {
            localFilteredList = localFilteredList.filter((item: WatchItemInterface) => item.sold === "true");
            localEGPSFilteredList = localEGPSFilteredList.filter((item: WatchItemInterface) => item.sold === "true");
        } else if (saleFilter === "unsold") {
            localFilteredList = localFilteredList.filter((item: WatchItemInterface) => item.sold === "false");
            localEGPSFilteredList = localEGPSFilteredList.filter((item: WatchItemInterface) => item.sold === "false");
        } else if (saleFilter === "priceRaise") {
            localFilteredList = localFilteredList.filter((item: WatchItemInterface) => {
                if (
                    !isNaN(item.priceHistory?.[0]?.priceChange) &&
                    item.priceHistory[0].priceChange > 0 &&
                    item.sold === "false"
                ) {
                    return true;
                }
                return false;
            });
            localEGPSFilteredList = localEGPSFilteredList.filter((item: WatchItemInterface) => {
                if (
                    !isNaN(item.priceHistory?.[0]?.priceChange) &&
                    item.priceHistory[0].priceChange > 0 &&
                    item.sold === "false"
                ) {
                    return true;
                }
                return false;
            });
        } else if (saleFilter === "priceDrop") {
            localFilteredList = localFilteredList.filter((item: WatchItemInterface) => {
                if (
                    !isNaN(item.priceHistory?.[0]?.priceChange) &&
                    item.priceHistory[0].priceChange < 0 &&
                    item.sold === "false"
                ) {
                    return true;
                }
                return false;
            });
            localEGPSFilteredList = localEGPSFilteredList.filter((item: WatchItemInterface) => {
                if (
                    !isNaN(item.priceHistory?.[0]?.priceChange) &&
                    item.priceHistory[0].priceChange < 0 &&
                    item.sold === "false"
                ) {
                    return true;
                }
                return false;
            });
        }

        if (categoryFilterOptions[categoryFilter]) {
            localFilteredList = localFilteredList.filter((item: WatchItemInterface) =>
                categoryFilterOptions[categoryFilter]?.some((categoryFilterItem) =>
                    item.name.toLowerCase().includes(categoryFilterItem)
                )
            );
            localEGPSFilteredList = localEGPSFilteredList.filter((item: WatchItemInterface) =>
                categoryFilterOptions[categoryFilter]?.some((categoryFilterItem) =>
                    item.name.toLowerCase().includes(categoryFilterItem)
                )
            );
        }

        if (!searchQuery) {
            setFilteredList(localFilteredList);
            setEGPSFilteredList(localEGPSFilteredList);
            return;
        }

        setFilteredList(
            localFilteredList.filter((item: WatchItemInterface) => {
                if (!item.name) {
                    return false;
                }
                return item.name.toLowerCase().includes(searchQuery.toLowerCase());
            })
        );
        setEGPSFilteredList(
            localEGPSFilteredList.filter((item: WatchItemInterface) => {
                if (!item.name) {
                    return false;
                }
                return item.name.toLowerCase().includes(searchQuery.toLowerCase());
            })
        );
    };

    useEffect(() => {
        const query = searchParams.get("query");
        if (query) {
            setSearchQuery(query);
        }
    }, []);

    useEffect(() => {
        // Instant update.
        filterList(searchQuery, saleFilter, selectedSearchStore, categoryFilter);
    }, [allWatchesList, watchList, watchList[selectedSearchStore], selectedSearchStore, saleFilter, categoryFilter]);

    useEffect(() => {
        // Handling Search input. Delayed update
        if (!watchList[selectedSearchStore] && selectedSearchStore !== "All" && selectedSearchStore !== "AllEGPS") {
            return;
        }
        delayedFilterList(searchQuery, saleFilter, selectedSearchStore, categoryFilter);
        setSearchParams({
            query: searchQuery
        });
    }, [searchQuery]);

    const egpsMatchWebsiteOrderList = [...filteredList].sort((a, b) => b.urlID - a.urlID);
    const presentedList =
        shouldMatchWebsite && selectedSearchStore === "EGPS" ? egpsMatchWebsiteOrderList : filteredList;
    return (
        <div className="relative h-full flex flex-col mx-auto pt-4 pl-4 pr-1 lg:p-8 sm:p-6 ">
            <span className="absolute hidden sm:block bottom-0 left-0 text-sm py-1 px-2 font-medium bg-gray-300 z-10">
                手錶總數: {isComparingEGPS && `${egpsFilteredList.length} | `} {filteredList.length}
            </span>
            <h1 className="text-2xl font-semibold text-gray-900 mr-2 pb-4 hidden md:block">搜尋</h1>
            <div className="flex gap-2 align-center">
                <div className="w-36">
                    <Dropdown
                        selected={selectedSearchStore}
                        items={["AllEGPS", "All", ...StoreList]}
                        itemsDisplayMap={{
                            AllEGPS: "所有+永生",
                            All: "所有商店",
                            ...StoreListEnglishToChinese
                        }}
                        setSelected={setSelectedSearchStore}
                    />
                </div>
                <div className="w-24">
                    <Dropdown
                        selected={saleFilter}
                        items={Object.keys(saleFilterOptions)}
                        itemsDisplayMap={saleFilterOptions}
                        setSelected={setSaleFilter}
                    />
                </div>
                <div className="w-36">
                    <Dropdown
                        selected={categoryFilter}
                        items={Object.keys(categoryFilterOptions)}
                        setSelected={setCategoryFilter}
                    />
                </div>
                {selectedSearchStore === "EGPS" && (
                    <Switch.Group as="div" className="flex items-center hidden sm:flex">
                        <Switch
                            checked={shouldMatchWebsite}
                            onChange={setShouldMatchWebsite}
                            className={classNames(
                                shouldMatchWebsite ? "bg-indigo-600" : "bg-gray-200",
                                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                            )}
                        >
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    shouldMatchWebsite ? "translate-x-5" : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                )}
                            />
                        </Switch>
                        <Switch.Label as="span" className="ml-1 text-sm">
                            {shouldMatchWebsite ? (
                                <span className="font-medium text-gray-900">同步網站</span>
                            ) : (
                                <span className="font-medium text-gray-900">同步日期</span>
                            )}
                        </Switch.Label>
                    </Switch.Group>
                )}

                <Switch.Group as="div" className="flex items-center hidden sm:flex">
                    <Switch
                        checked={isComparingEGPS}
                        onChange={setIsComparingEGSP}
                        className={classNames(
                            isComparingEGPS ? "bg-indigo-600" : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                        )}
                    >
                        <span
                            aria-hidden="true"
                            className={classNames(
                                isComparingEGPS ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                        />
                    </Switch>
                    <Switch.Label as="span" className="ml-1 text-sm">
                        <span className="font-medium text-gray-900">相比</span>
                    </Switch.Label>
                </Switch.Group>
            </div>
            <div className="w-full mt-2 p-1 bg-white rounded-md m-auto shadow-sm border border-gray-300">
                <div className="w-full flex md:ml-0">
                    <label htmlFor="search-field" className="sr-only">
                        Search
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                            <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                            id="search-field"
                            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                            placeholder="Search"
                            type="search"
                            name="search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="flex-1 relative mt-5 flex flex-row">
                {isComparingEGPS && (
                    <div className="flex-1 relative">
                        <div className="absolute top-0 right-0 left-0 bottom-0 overflow-y-scroll pb-5" ref={scrollRef}>
                            <div className="gap-4 grid watchItemTemplate">
                                {egpsFilteredList.slice(0, currentlyLoaded).map((watchItem: WatchItemInterface) => (
                                    <WatchItem
                                        {...watchItem}
                                        getImageCache={getImageCache}
                                        key={watchItem.privateID || watchItem.watchID || watchItem.link}
                                    />
                                ))}
                                {currentlyLoaded < presentedList.length && (
                                    <div className="w-full aspect-w-16 aspect-h-9 object-contain relative">
                                        <div
                                            onClick={loadMore}
                                            className="absolute top-0 right-0 left-0 bottom-0 card flex flex-col overflow-hidden items-center justify-center hover:cursor-pointer hover:bg-gray-200 font-medium "
                                        >
                                            加載更多...
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex-1 relative">
                    <div className="absolute top-0 right-0 left-0 bottom-0 overflow-y-scroll pb-5" ref={scrollRef}>
                        <div className="gap-4 grid watchItemTemplate">
                            {presentedList.slice(0, currentlyLoaded).map((watchItem: WatchItemInterface) => (
                                <WatchItem
                                    {...watchItem}
                                    getImageCache={getImageCache}
                                    key={watchItem.privateID || watchItem.watchID || watchItem.link}
                                />
                            ))}
                            {currentlyLoaded < presentedList.length && (
                                <div className="w-full aspect-w-16 aspect-h-9 object-contain relative">
                                    <div
                                        onClick={loadMore}
                                        className="absolute top-0 right-0 left-0 bottom-0 card flex flex-col overflow-hidden items-center justify-center hover:cursor-pointer hover:bg-gray-200 font-medium "
                                    >
                                        加載更多...
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Search;
