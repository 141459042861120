import { useEffect, useState } from "react";
import { useAdminContext } from "../context/Admin";
import { UserActivityInterface } from "../Interfaces";
import { Dropdown } from "../components";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import dayjs from "dayjs";

const knownIPMap: { [key: string]: string } = {
    "118.170.228.83": "公司"
};
function Admin() {
    const { usersActivity, deviceHashMap, updateActivityList, employees } = useAdminContext();
    const [updating, setUpdating] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState("全部");
    const [dateRange, setDateRange] = useState<DateValueType>({
        startDate: new Date(new Date().setHours(0, 0, 0, 1)),
        endDate: new Date(new Date().setHours(23, 59, 59, 59))
    });

    const refreshActivity = async () => {
        if (updating) {
            return;
        }
        setUpdating(true);
        await updateActivityList(employees[selectedEmployee], {
            startDate: dayjs(dateRange?.startDate as string).valueOf(),
            endDate: dayjs(dateRange?.endDate as string)
                .hour(23)
                .minute(59)
                .second(59)
                .valueOf()
        });
        setUpdating(false);
    };

    const handleValueChange = (newValue: DateValueType) => {
        setDateRange(newValue);
    };

    useEffect(() => {
        if (!dateRange?.startDate || !dateRange.endDate) {
            return;
        }
        refreshActivity();
    }, [selectedEmployee, dateRange]);

    const pathnameProcessor = (pathname: string) => {
        const urlArray = pathname.split("?");
        switch (urlArray[0]) {
            case "admin":
                return "管理";
            case "settings":
                return "設置";
            case "stats":
                return "數據";
            case "warranty":
                return "保修單";
            case "yahoo":
                return "Yahoo";
            // case "compare":
            //     return "相比";
            case "search":
                // eslint-disable-next-line no-case-declarations
                const query = urlArray[1]?.replace("query=", "");
                if (query) {
                    return `搜尋=${query}`;
                }
                return `搜尋`;
            case "dashboard":
                return "控制板";
            case "":
                return "主頁";
            default:
                return `/${pathname}`;
                break;
        }
    };

    return (
        <div className="relative h-full flex flex-col mx-auto pt-4 pl-4 pr-1 lg:p-8 sm:p-6 ">
            <span className="absolute hidden sm:block bottom-0 left-0 text-sm py-1 px-2 font-medium bg-gray-300 z-10">
                記錄總計: {usersActivity?.length}
            </span>
            <div className="flex flex-row align-center">
                <h1 className="text-2xl font-semibold text-gray-900 mr-2 pb-4 hidden md:block">管理 </h1>
                <div className="flex gap-2 align-center">
                    {updating ? (
                        <button
                            type="button"
                            className="max-h-10 text-bold inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-slate-600 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:cursor-not-allowed"
                        >
                            <svg
                                className="-ml-1 mr-2 h-5 w-5 animate-spin"
                                aria-hidden="true"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 24 24"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M10 11H7.101l.001-.009a4.956 4.956 0 0 1 .752-1.787 5.054 5.054 0 0 1 2.2-1.811c.302-.128.617-.226.938-.291a5.078 5.078 0 0 1 2.018 0 4.978 4.978 0 0 1 2.525 1.361l1.416-1.412a7.036 7.036 0 0 0-2.224-1.501 6.921 6.921 0 0 0-1.315-.408 7.079 7.079 0 0 0-2.819 0 6.94 6.94 0 0 0-1.316.409 7.04 7.04 0 0 0-3.08 2.534 6.978 6.978 0 0 0-1.054 2.505c-.028.135-.043.273-.063.41H2l4 4 4-4zm4 2h2.899l-.001.008a4.976 4.976 0 0 1-2.103 3.138 4.943 4.943 0 0 1-1.787.752 5.073 5.073 0 0 1-2.017 0 4.956 4.956 0 0 1-1.787-.752 5.072 5.072 0 0 1-.74-.61L7.05 16.95a7.032 7.032 0 0 0 2.225 1.5c.424.18.867.317 1.315.408a7.07 7.07 0 0 0 2.818 0 7.031 7.031 0 0 0 4.395-2.945 6.974 6.974 0 0 0 1.053-2.503c.027-.135.043-.273.063-.41H22l-4-4-4 4z"></path>
                            </svg>
                            更新
                        </button>
                    ) : (
                        <button
                            onClick={refreshActivity}
                            type="button"
                            className="max-h-10 text-bold inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <svg
                                className="-ml-1 mr-2 h-5 w-5"
                                aria-hidden="true"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 24 24"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M10 11H7.101l.001-.009a4.956 4.956 0 0 1 .752-1.787 5.054 5.054 0 0 1 2.2-1.811c.302-.128.617-.226.938-.291a5.078 5.078 0 0 1 2.018 0 4.978 4.978 0 0 1 2.525 1.361l1.416-1.412a7.036 7.036 0 0 0-2.224-1.501 6.921 6.921 0 0 0-1.315-.408 7.079 7.079 0 0 0-2.819 0 6.94 6.94 0 0 0-1.316.409 7.04 7.04 0 0 0-3.08 2.534 6.978 6.978 0 0 0-1.054 2.505c-.028.135-.043.273-.063.41H2l4 4 4-4zm4 2h2.899l-.001.008a4.976 4.976 0 0 1-2.103 3.138 4.943 4.943 0 0 1-1.787.752 5.073 5.073 0 0 1-2.017 0 4.956 4.956 0 0 1-1.787-.752 5.072 5.072 0 0 1-.74-.61L7.05 16.95a7.032 7.032 0 0 0 2.225 1.5c.424.18.867.317 1.315.408a7.07 7.07 0 0 0 2.818 0 7.031 7.031 0 0 0 4.395-2.945 6.974 6.974 0 0 0 1.053-2.503c.027-.135.043-.273.063-.41H22l-4-4-4 4z"></path>
                            </svg>
                            更新
                        </button>
                    )}
                    {Object.keys(employees).length > 0 && (
                        <div className="max-h-10 w-36">
                            <Dropdown
                                selected={selectedEmployee}
                                items={["全部", ...Object.keys(employees)]}
                                setSelected={setSelectedEmployee}
                            />
                        </div>
                    )}

                    <div className="max-h-10 w-60 hidden sm:block">
                        <Datepicker
                            containerClassName="relative w-full border border-gray-300 rounded-md shadow-sm"
                            value={dateRange}
                            onChange={handleValueChange}
                            showShortcuts={true}
                            // i18n={"zh-tw"}
                            primaryColor="blue"
                            maxDate={new Date()}
                            configs={{
                                shortcuts: {
                                    today: "今天",
                                    yesterday: "昨天",
                                    past: (period) => `過去 ${period} 天`,
                                    currentMonth: "這個月",
                                    pastMonth: "上個月"
                                },
                                footer: {
                                    cancel: "取消",
                                    apply: "選擇"
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="max-h-10 w-60  block sm:hidden mt-1">
                <Datepicker
                    containerClassName="relative w-full border border-gray-300 rounded-md shadow-sm"
                    value={dateRange}
                    onChange={handleValueChange}
                    showShortcuts={true}
                    // i18n={"zh-tw"}
                    primaryColor="blue"
                    maxDate={new Date()}
                    configs={{
                        shortcuts: {
                            today: "今天",
                            yesterday: "昨天",
                            past: (period) => `過去 ${period} 天`,
                            currentMonth: "這個月",
                            pastMonth: "上個月"
                        },
                        footer: {
                            cancel: "取消",
                            apply: "選擇"
                        }
                    }}
                />
            </div>
            <div className="flex-1 relative">
                <div className="absolute top-0 right-0 left-0 bottom-0 overflow-y-scroll pb-5">
                    <div className="mt-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 mx-2 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        姓名
                                    </th>

                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        時間
                                    </th>
                                    <th
                                        scope="col"
                                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                                    >
                                        網頁
                                    </th>
                                    <th
                                        scope="col"
                                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                    >
                                        IP
                                    </th>
                                    <th
                                        scope="col"
                                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                    >
                                        設備
                                    </th>

                                    <th
                                        scope="col"
                                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                    >
                                        地點
                                    </th>
                                </tr>
                            </thead>
                            {usersActivity === false ? (
                                "發生錯誤。 聯繫布萊恩"
                            ) : (
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {usersActivity?.map((activity: UserActivityInterface) => (
                                        <tr key={`${activity.id}-${activity.timestamp}`}>
                                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                {activity.name || activity.username}
                                                <dl className="font-normal lg:hidden">
                                                    <dt className="sr-only">Page</dt>
                                                    <dd className="mt-1 truncate text-gray-700">
                                                        {pathnameProcessor(decodeURI(activity.pathname))}
                                                    </dd>
                                                    <dt className="sr-only sm:hidden">IP</dt>
                                                    <dd className="mt-1 truncate text-gray-500 sm:hidden">
                                                        {activity.cognitoIP}
                                                    </dd>
                                                </dl>
                                            </td>

                                            <td className="px-3 py-4 text-sm text-gray-500">
                                                {new Date(activity.timestamp).toLocaleDateString("en-us", {
                                                    month: "numeric",
                                                    day: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hourCycle: "h24"
                                                })}
                                            </td>
                                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell max-w-sm break-words">
                                                {pathnameProcessor(decodeURI(activity.pathname))}
                                            </td>
                                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                {activity.cognitoIP}
                                                {knownIPMap[activity.cognitoIP] &&
                                                    `(${knownIPMap[activity.cognitoIP]})`}
                                            </td>
                                            {deviceHashMap[activity.userFingerprintHash] ? (
                                                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                    {activity.userFingerprintHash}
                                                </td>
                                            ) : (
                                                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                    --
                                                </td>
                                            )}
                                            {activity.locationAddress ? (
                                                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                    {activity.locationAddress}
                                                </td>
                                            ) : (
                                                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                    --
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Admin;
